'use strict';

window.wardNameList = [];
window.wardsData = [];

(function($, L, generateChart, d3, analysis, wardNames, wardDetails, window) {
  var mapElement;
  var busIcon = L.icon({
    iconUrl: '/images/bus.png',
    shadowUrl: '',
    iconSize: [20, 20], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    iconAnchor: [9, 9], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
  });
  var busFlag = 0;
  var fireFlag = 0;

  function findWard(wordTextBox) {
    var wardName = wordTextBox[0].value;
    if(wardName !== '') {
      if (wardName in wardNames) {
        var jsonHTMLTable = '<table class="ui celled table" style="padding: 15px;border-color:black; border-width:3px">';
        var keys = [
          'ward_id', 'WARD_NAME', 'cluster_id', 'park_area',
          'ZONE', 'population_density', 'public_toilet_count',
          'park_count', 'complaint_count', 'no_of_households',
          'waste_collection_center_count', 'complaint_count_rank',
          'park_area_per_population_rank', 'population_density_rank',
          'no_of_households_rank', 'ward_area_rank', 'relative_rank'
        ];
        for(var key in keys) {
          if(wardNames[wardName][keys[key]]) {
            jsonHTMLTable += '<tr><td><b>' + keys[key].toLocaleLowerCase() + '</b></td><td>' + wardNames[wardName][keys[key]] + '</td></tr>';
          }
        }
        jsonHTMLTable += '</table>';
        $('#chart').empty();
        $('#chart').html(jsonHTMLTable);
        $('.ui.basic.modal').modal('show');
      }
      else {
        alert('Please enter a valid ward name!');
      }
    }
  }

  $.when(
    $.get('/infer_similar_places'),
    $.get('/bus_stop'),
    $.get('/firestation')
  ).done(function(data, busData, firestationData) {
    var propertyName, label = '', minColor, maxColor;
    wardDetails = JSON.parse(data[0]);
    analysis(wardDetails);
    var busMarkers = JSON.parse(busData[0]);
    var fireMarkers = JSON.parse(firestationData[0]);
    function visualize() {
      if(mapElement) {
        mapElement.remove();
      }
      mapElement = L.map('map').setView([13, 77.57693], 12);
      L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpandmbXliNDBjZWd2M2x6bDk3c2ZtOTkifQ._QA7i5Mpkd_m30IGElHziw', {
        maxZoom: 18,
        id: 'mapbox.light'
      }).addTo(mapElement);

      // control that shows state info on hover

      var info = L.control();
      info.onAdd = function() {
        this.div = L.DomUtil.create('div', 'info');
        this.update();
        return this.div;
      };

      info.update = function (props) {
        if(propertyName !== 'WARD_NO') {
          this.div.innerHTML = '<h4>Wards Data</h4>' + (props ?
            '<b>' + props.WARD_NAME + '</b><br />' + label + ': ' + props[propertyName] + '</br>'
            : 'Hover over a ward');
          }
          else {
            this.div.innerHTML = '<h4>Wards Data</h4>' + (props ?
              '<b>' + props.WARD_NAME + '</b><br />' + label + ': ' + props[propertyName] + '</br>Households :' + props.no_of_households
              + '</br>Park Count:' + props.park_count + '</br>  Complaint Count:' + props.complaint_count + '</br> Ward Area: ' + props.ward_area
              : 'Hover over a state');
            }
          };

          info.addTo(mapElement);

          // get color depending on population density value
          function getColorScale(minValue, maxValue, minColorValue, maxColorValue) {
            return d3.scale.linear().domain([minValue, maxValue]).range([minColorValue, maxColorValue]);
          }
          function getCategoryScale(){
            return d3.scale.category10();
          }
          function addBusMarkers(json) {
            var stopsInside = json.inside_bbmp_limits;
            var stopsOutside = json.outside_bbmp_limits;
            var marker;
            var stop;
            for (stop in stopsInside) {
              marker = stopsInside[stop];
              L.marker([
                marker.bus_stop_latitude,
                marker.bus_stop_longitude
              ], {icon: busIcon})
              .addTo(mapElement)
              .bindPopup(marker.ward);
            }
            for (stop in stopsOutside){
              marker = stopsOutside[stop];
              L.marker([
                marker.bus_stop_latitude,
                marker.bus_stop_longitude
              ], {icon: busIcon})
              .addTo(mapElement)
              .bindPopup(marker.ward);
            }
          }

          function addFireMarkers(json) {
            var stopsInside = json.inside_bbmp_limits;
            for (var stop in stopsInside){
              var marker = stopsInside[stop];
              L.marker([
                marker.firestation_latitude,
                marker.firestation_longitude
              ]).addTo(mapElement);
            }
          }

          if(busFlag) {
            addBusMarkers(busMarkers);
          }
          if(fireFlag) {
            addFireMarkers(fireMarkers);
          }
          function getMinWardValue(property) {
            var i = 0;
            while(wardDetails.features[i].properties[property] === undefined && i < wardDetails.features.length) {
              i++;
            }
            var minIndex = i;
            var minValue = wardDetails.features[minIndex].properties[property];
            for(i = 0; i < wardDetails.features.length; i++) {
              if(wardDetails.features[i].properties[property] < minValue) {
                minValue = wardDetails.features[i].properties[property];
                minIndex = i;
              }
              if(wardDetails.features[i].properties[property] === undefined) {
                wardDetails.features[i].properties[property] = 0;
              }
            }
            return minValue;
          }

          function getMaxWardValue(property) {
            var maxIndex = 0;
            var maxValue = wardDetails.features[maxIndex].properties[property];
            for(var i = 1; i < wardDetails.features.length; i++) {
              if(wardDetails.features[i].properties[property] > maxValue) {
                maxValue = wardDetails.features[i].properties[property];
                maxIndex = i;
              }
            }

            return maxValue;
          }

          var minDensity = getMinWardValue(propertyName);
          var maxDensity = getMaxWardValue(propertyName);
          var colorScale = getCategoryScale();

          if(propertyName !== 'cluster_id') {
            colorScale = getColorScale(minDensity, maxDensity, minColor, maxColor);
          }

          function style(feature) {
            return {
              weight: 2,
              opacity: 1,
              color: 'white',
              dashArray: '3',
              fillOpacity: 0.5,
              fillColor: colorScale(feature.properties[propertyName])
            };
          }

          function highlightFeature(e) {
            var layer = e.target;
            layer.setStyle({
              weight: 5,
              color: '#666',
              dashArray: '',
              fillOpacity: 0.8
            });
            if (!L.Browser.ie && !L.Browser.opera) {
              layer.bringToFront();
            }
            info.update(layer.feature.properties);
          }

          function zoomToFeature(e) {
            mapElement.fitBounds(e.target.getBounds());
          }

          function onEachFeature(feature, layer) {
            var resetHighlight = function(e) {
              geojson.resetStyle(e.target);
              info.update();
            };
            layer.on({
              mouseover: highlightFeature,
              mouseout: resetHighlight,
              click: (propertyName === 'complaint_count' ) ? (event) => {
                $('.ui.basic.modal').modal('show');
                generateChart(event.target.feature.properties);
                window.dispatchEvent(new Event('resize'));
              }
              : zoomToFeature
            });
          }
          var geojson = L.geoJson(wardDetails, {
            style: style,
            onEachFeature: onEachFeature
          }).addTo(mapElement);

          var legend = L.control({position: 'bottomright'});

          legend.onAdd = function() {
            var firstQuarter = (( 3 * minDensity + maxDensity) / 4),
            mean = ((minDensity + maxDensity) / 2),
            secondQuarter = ((minDensity + maxDensity * 3) / 4);
            var div = L.DomUtil.create('div', 'info legend');
            var grades = [
              Math.floor(minDensity),
              Math.floor(firstQuarter),
              Math.floor(mean),
              Math.floor(secondQuarter),
              Math.floor(maxDensity)
            ];
            var labels = [];
            for (var i = 0; i < grades.length; i++) {
              var from = grades[i];
              var to = grades[i + 1];
              labels.push(
                '<i style="background:' + colorScale(from) + '"></i> ' +
                from + (to ? '&ndash;' + to : '+')
              );
            }
            div.innerHTML = labels.join('<br>');
            return div;
          };

          if((propertyName !== 'cluster_id') && (propertyName !== 'WARD_NO')) {
            legend.addTo(mapElement);
          }
        }

        $('#wards').click(function() {
          propertyName = 'WARD_NO';
          label = 'Ward Number';
          minColor = 'grey';
          maxColor = 'grey';
          visualize();
        });

        $('#parks').click(function() {
          propertyName = 'park_count';
          minColor = 'grey';
          maxColor = 'green';
          label = 'Park Count';
          visualize();
        });

        $('#bus').click(function() {
          busFlag = !busFlag;
          visualize();
        });

        $('#firestation').click(function() {
          fireFlag = !fireFlag;
          visualize();
        });

        $('#complaints').click(function() {
          propertyName = 'complaint_count';
          minColor = 'grey';
          maxColor = 'red';
          label = 'Complaint Count';
          visualize();
        });

        $('#population').click(function() {
          propertyName = 'population_density';
          minColor = 'green';
          maxColor = 'red';
          label = 'Population Density';
          visualize();
        });

        $('#toilets').click(function() {
          propertyName = 'public_toilet_count';
          minColor = 'grey';
          maxColor = 'red';
          label = 'Public Toilet Count';
          visualize();
        });

        $('#cluster').click(function(){
          label = 'Cluster Number';
          propertyName = 'cluster_id';
          minColor = 'grey';
          maxColor = 'blue';
          visualize();
        });

        propertyName = 'WARD_NO';
        label = 'Ward Number';
        minColor = 'grey';
        maxColor = 'grey';
        visualize();
      });
      $('#search').click(function(){
        findWard(document.getElementsByName('ward_name'));
      });
    })(window.jQuery, window.L, window.generateChart, window.d3, window.analysis, window.wardNameList, window.wardsData, window);
